import { useEffect } from "react";

const alignCookiebotWithButton = () => {
  const targetButton = document.querySelector(".toggle-dark");
  const cookiebot = document.getElementById("CookiebotWidget");

  if (targetButton && cookiebot) {
    const buttonRect = targetButton.getBoundingClientRect();
    const { top, left, width, height } = buttonRect;
    const bottomPosition = window.innerHeight - (top + height + window.scrollY);
    cookiebot.style.position = "absolute";
    cookiebot.style.left = `${window.scrollX + left}px`;
    cookiebot.style.bottom = `${bottomPosition - 8}px`;
  }
};

const useAlignCookiebot = () => {
  useEffect(() => {
    alignCookiebotWithButton();
    window.addEventListener("scroll", alignCookiebotWithButton);
    window.addEventListener("resize", alignCookiebotWithButton);

    const observer = new MutationObserver(alignCookiebotWithButton);
    observer.observe(document.body, { childList: true, subtree: true });

    return () => {
      window.removeEventListener("scroll", alignCookiebotWithButton);
      window.removeEventListener("resize", alignCookiebotWithButton);
      observer.disconnect();
    };
  }, []);
};

export default useAlignCookiebot;
