import { useEffect } from "react";
import { useLocation } from "react-router-dom";
import { tracer } from "./tracing";

const RouteTracker = () => {
  const location = useLocation();

  useEffect(() => {
    const span = tracer.startSpan(
      `Navigation: ${location.pathname + location.search + location.hash}`
    );
    span.setAttribute("path", location.pathname);
    span.setAttribute("sessionId", localStorage.getItem("sessionId"));
    span.end();
  }, [location]);

  return null;
};

export default RouteTracker;
