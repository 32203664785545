import { getWebAutoInstrumentations } from "@opentelemetry/auto-instrumentations-web";
import {
  BatchSpanProcessor,
  ConsoleSpanExporter,
  SimpleSpanProcessor,
  WebTracerProvider
} from "@opentelemetry/sdk-trace-web";
import { ZoneContextManager } from "@opentelemetry/context-zone";
import { OTLPTraceExporter } from "@opentelemetry/exporter-trace-otlp-http";
import { SemanticResourceAttributes } from "@opentelemetry/semantic-conventions";
import { Resource } from "@opentelemetry/resources";
import { registerInstrumentations } from "@opentelemetry/instrumentation";
import { trace } from "@opentelemetry/api";
import { FetchInstrumentation } from "@opentelemetry/instrumentation-fetch";
import { XMLHttpRequestInstrumentation } from "@opentelemetry/instrumentation-xml-http-request";

const serviceName = "dev-ec-main-ui";

const envList = [
  "localhost",
  "dev"
  // "acc", "ref", "eclear-solutions.com"
];

let enableOTEL = false;

for (const keyword of envList) {
  if (window.location.host.includes(keyword)) {
    enableOTEL = true;
    break;
  }
}

const collectorOptions = {
  url: "https://otel-col.eclear-solutions.com/",
  headers: {
    "Content-Type": "application/json",
    "Access-Control-Allow-Origin": "*"
  }
};

const providerConfig = {
  resource: new Resource({
    [SemanticResourceAttributes.SERVICE_NAME]: serviceName
  })
};

const provider = new WebTracerProvider(providerConfig);

if (enableOTEL) {
  provider.addSpanProcessor(
    new SimpleSpanProcessor(new OTLPTraceExporter(collectorOptions))
  );

  provider.register({
    contextManager: new ZoneContextManager()
  });

  function addSessionIdToSpan(span) {
    const sessionId = localStorage.getItem("sessionId");
    span.setAttribute("sessionId", sessionId);
  }

  registerInstrumentations({
    instrumentations: [
      getWebAutoInstrumentations({
        "@opentelemetry/instrumentation-user-interaction": {
          enabled: false
        }
      }),
      new FetchInstrumentation({
        fetchHook: (span) => {
          addSessionIdToSpan(span);
        }
      }),
      new XMLHttpRequestInstrumentation({
        beforeRequest: (span) => {
          addSessionIdToSpan(span);
        }
      })
    ],
    tracerProvider: provider,
    beforeStart: (spanName, options) => {
      const span = trace.getTracer("web-tracer").startSpan(spanName, options);
      addSessionIdToSpan(span);
      return span;
    }
  });
}

export const tracer = provider.getTracer(serviceName);

export default function TraceProvider({ children }) {
  return <>{children}</>;
}
