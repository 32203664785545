export const invalidUsernamePassword = [
  {
    inputName: "form",
    title: "loginFailed",
    messages: [
      {
        text: "loginFailed.error",
        type: "error"
      }
    ],
    type: "error"
  }
];

export const errorMessages = [
  {
    title: "somethingIsNotRight",
    messages: [
      {
        text: "email.error",
        type: "error"
      },
      {
        text: "password.error",
        type: "error"
      }
    ]
  }
];

export const tenantDeactivated = [
  {
    title: "loginFailed",
    messages: [
      {
        text: "user.deactivated",
        type: "error"
      }
    ]
  }
];


export const userDeactivated = [
  {
    title: "loginFailed",
    messages: [
      {
        text: "user.user",
        type: "error"
      }
    ]
  }
];
