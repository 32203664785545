import React, { useState, useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Divider, Grid, CircularProgress } from "@mui/material";
import { FormattedMessage } from "react-intl";
import { ECAxiosAPI, EcAuthContext } from "@eclear-ag/ec-auth";
import { URLS } from "../../configuration/urls";
import ValidationMessage from "../../components/validationMessage";
import { apiError } from "./constants";
import {
  countriesList_de,
  countriesList_en
} from "../CompanyInfo/countries_data";
import { error_message } from "../../helpers/validation_messages";
import { useForm } from "react-hook-form";
import FormTextField from "../../components/_eform/form-elements/FormTextField";
import FormDropDown from "../../components/_eform/form-elements/FormDropDown";

const InvoiceForm = () => {
  const { userData } = useContext(EcAuthContext);

  const [isNextLoading, setIsNextLoading] = useState(false);
  const [isErrorVisible, setIsErrorVisible] = useState(false);
  const [editMode, setEditMode] = useState(false);
  const [validationMessage, setValidationMessage] = useState([]);

  const {
    handleSubmit,
    formState: { errors },
    control,
    reset,
    getValues
  } = useForm({
    mode: "onChange",
    defaultValues: {
      companyName: "",
      houseNumber: "",
      street: "",
      postalCode: "",
      city: "",
      country: ""
    }
  });

  const navigate = useNavigate();
  useEffect(() => {
    const saved = JSON.parse(localStorage.getItem("invoiceData"));
    !localStorage.getItem("invoiceData")
      ? reset({
          companyName: userData?.companyDetails?.[0]?.name,
          houseNumber: userData?.companyDetails?.[0]?.houseNumber,
          street: userData?.companyDetails?.[0]?.street,
          postalCode: userData?.companyDetails?.[0]?.postalCode,
          city: userData?.companyDetails?.[0]?.city,
          country: userData?.companyDetails?.[0]?.country
        })
      : reset({ ...saved, companyName: saved.name });
  }, [userData]);

  const payWithInvoice = async () => {
    setIsErrorVisible(false);
    setIsNextLoading(true);
    await ECAxiosAPI.get(
      `${URLS.EC_AUTH_BE()}product/find-by-product-type/SPOT`
    )
      .then((response) => {
        ECAxiosAPI.post(
          `${URLS.EC_AUTH_BE()}${URLS.CREATE_INVOICE_SUBSCRIPTION}`,
          {
            merchantId: userData?.companyDetails?.[0]?.merchantId,
            // shopId: userData?.userShopDetails?.primaryShop?.id,
            productId: response.id,
            billingAddress: {
              houseNumber: getValues("houseNumber"),
              street: getValues("street"),
              postalCode: getValues("postalCode"),
              city: getValues("city"),
              country: getValues("country")
            }
          }
        )
          .then((res) => {
            setIsErrorVisible(false);
            window.location.href = "/spot/";
          })
          .catch((err) => {
            setValidationMessage(apiError);
            setIsErrorVisible(true);
            setIsNextLoading(false);
          });
      })
      .catch((err) => {
        setValidationMessage(
          error_message(
            err.response.data.message || "yourRequestCouldNotBeProcessed"
          )
        );
        setIsErrorVisible(true);
        setIsNextLoading(false);
      });

    setIsNextLoading(false);
  };

  const BillingAddressInfo = () => {
    return (
      <div style={{ padding: "8px 16px" }}>
        <span
          style={{ fontWeight: 500 }}
          data-testId="postal-code-country-code"
        >
          {getValues("postalCode")} {getValues("country")}
        </span>
        <br />
        <span data-testId="city">{getValues("city")}</span>
        <br />
        <span data-testId="street-house-number">
          {getValues("street")} {getValues("houseNumber")}
        </span>
        <br />
        <span data-testId="company-name">{getValues("companyName")}</span>
        <br />
        <Button
          data-testid="edit-button"
          disableElevation
          variant="contained"
          style={{ margin: "16px 0px", textTransform: "none" }}
          onClick={() => {
            setIsErrorVisible(false);
            setEditMode(true);
          }}
        >
          <FormattedMessage id="edit" />
        </Button>
      </div>
    );
  };

  const saveBillingInfo = () => {
    setEditMode(false);
    localStorage.setItem("invoiceData", JSON.stringify(getValues()));
  };

  return (
    <div
      style={{ borderRadius: "4px", width: "70%" }}
      className="ec-box"
      data-testId={editMode ? "billing-address-box" : "invoice-data-box"}
    >
      <div style={{ padding: "12px 16px", fontSize: "20px", fontWeight: 500 }}>
        <span>
          {" "}
          <FormattedMessage id="yourBillingAddress" />
        </span>
      </div>
      <Divider />

      {editMode ? (
        <form onSubmit={handleSubmit(saveBillingInfo)}>
          <Grid container spacing={2} style={{ padding: "8px 16px" }}>
            <Grid item md={12} sm={12}>
              <FormTextField
                width={12}
                errors={errors}
                control={control}
                label={"companyName"}
                required
                disabled
                errorTestId="company-name-error-message"
                inputProps={{
                  "data-testid": "comany-name-form"
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormTextField
                width={12}
                errors={errors}
                control={control}
                label={"street"}
                required
                errorTestId="street-error-message"
                inputProps={{
                  "data-testid": "street-input"
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormTextField
                width={12}
                errors={errors}
                control={control}
                label={"houseNumber"}
                inputProps={{
                  "data-testid": "house-number-input"
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormTextField
                width={12}
                errors={errors}
                control={control}
                label={"city"}
                required
                errorTestId="city-error-message"
                inputProps={{
                  "data-testid": "city-input"
                }}
              />
            </Grid>
            <Grid item md={6} sm={12}>
              <FormTextField
                width={12}
                errors={errors}
                control={control}
                label={"postalCode"}
                validation={{
                  matchPattern: (value) => /^[A-Za-z0-9]*$/.test(value)
                }}
                validationMessage={"Incorrect Postal Code"}
                required
                errorTestId="postal-code-error-message"
                inputProps={{
                  "data-testid": "postal-code-input"
                }}
              />
            </Grid>
            <Grid item md={12} sm={12}>
              <FormDropDown
                data-testid="country-dropdown"
                control={control}
                width={6}
                placeholder={<FormattedMessage id="country_placeholder" />}
                label={"country"}
                errors={errors}
                options={
                  userData.lang === "en" ? countriesList_en : countriesList_de
                }
                required
                errorTestId="country-error-message"
                inputProps={{
                  "data-testid": "country-dropdown"
                }}
              />
            </Grid>
          </Grid>

          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              alignItems: "center",
              padding: "0px 24px"
            }}
          >
            <Button
              data-testid="cancel-button"
              aria-label="Cancel"
              disableElevation
              style={{ textTransform: "none" }}
              onClick={() => {
                const saved = JSON.parse(localStorage.getItem("invoiceData"));
                setEditMode(false);
                !localStorage.getItem("invoiceData")
                  ? reset({
                      companyName: userData?.companyDetails?.[0]?.name,
                      houseNumber: userData?.companyDetails?.[0]?.houseNumber,
                      street: userData?.companyDetails?.[0]?.street,
                      postalCode: userData?.companyDetails?.[0]?.postalCode,
                      city: userData?.companyDetails?.[0]?.city,
                      country: userData?.companyDetails?.[0]?.country
                    })
                  : reset({ ...saved, companyName: saved.name });
              }}
            >
              <FormattedMessage id="cancel" />
            </Button>
            <Button
              data-testid="save-button"
              aria-label="Save"
              variant="contained"
              disableElevation
              style={{ margin: "16px", textTransform: "none" }}
              type="submit"
            >
              <FormattedMessage id="save" />
            </Button>
          </div>
        </form>
      ) : (
        <BillingAddressInfo />
      )}

      <React.Fragment>
        <div
          style={{
            padding: "0rem 1rem 1rem 1rem",
            fontSize: "12px",
            opacity: "0.6"
          }}
        >
          <span>
            <FormattedMessage id="required" />
          </span>
        </div>
        <div
          style={{
            padding: "0px 16px",
            fontSize: "12px",
            opacity: "0.6"
          }}
        >
          <span>
            **
            <FormattedMessage id="after90Days" />
          </span>
        </div>
      </React.Fragment>
      <div style={{ margin: "16px" }}>
        <ValidationMessage
          visibility={isErrorVisible}
          setVisibility={setIsErrorVisible}
          messages={validationMessage}
        />
      </div>
      {!editMode && (
        <React.Fragment>
          <Divider />
          <div
            style={{
              display: "flex",
              justifyContent: "space-between",
              padding: "24px 28px 20px"
            }}
          >
            <Button
              data-testid="back-button"
              aria-label="Back"
              disableElevation
              onClick={() => {
                navigate("/signup/select-payment-method");
              }}
              style={{ textTransform: "none" }}
            >
              <FormattedMessage id="back" />
            </Button>
            <Button
              data-testid="subscribe-button"
              aria-label="Subscribe"
              disableElevation
              variant="contained"
              type="submit"
              style={{ textTransform: "none" }}
              disabled={isNextLoading}
              startIcon={
                isNextLoading && (
                  <CircularProgress style={{ width: "14px", height: "14px" }} />
                )
              }
              onClick={(e) => {
                e.preventDefault();
                payWithInvoice();
              }}
            >
              <FormattedMessage id="subscribe" />
            </Button>
          </div>
        </React.Fragment>
      )}
    </div>
  );
};

export default InvoiceForm;
