import messages_en from "../translations/en.json";
import messages_de from "../translations/de.json";
import React from "react";
import { tracer } from "../tracing";

const messages = {
  en: messages_en,
  de: messages_de
};

const localLang = localStorage.getItem("lang");

export const formattedMessage = (lang, message) => {
  return messages[lang || localLang][message];
};

export class ErrorBoundary extends React.Component {
  state = { hasErrors: false };

  componentDidCatch(error, info) {
    const location = window.location;
    const span = tracer.startSpan(
      `Critical error at route: ${location.pathname}`
    );
    span.setAttribute("path", location.pathname);
    span.setAttribute("errorDetails", error.toString());
    span.setAttribute("sessionId", localStorage.getItem("sessionId"));
    span.end();
  }

  render() {
    if (this.state.hasError) {
      <div>Fallback UI</div>;
    }
    return this.props.children;
  }
}
