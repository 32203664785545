import React, { useContext } from "react";
import messages_de from "../translations/de.json";
import messages_en from "../translations/en.json";
import { EcAuthContext } from "@eclear-ag/ec-auth";
import { FormattedMessage } from "react-intl";
import { Box, Button, ButtonGroup, Typography, Link } from "@mui/material";
import ConfigContext from "../services/config";
import useAlignCookiebot from "../helpers/useAlignCookiebot";
import CookieBot from "react-cookiebot";

export default function FooterLink({ style }) {
  const messages = {
    de: messages_de,
    en: messages_en
  };
  const { userData } = useContext(EcAuthContext);

  const { setIsDarkMode } = React.useContext(ConfigContext);
  useAlignCookiebot();

  return (
    <Box
      style={{
        display: "flex",
        alignItems: "center",
        justifyContent: "center",
        marginBottom: "15px"
      }}
    >
      <Box
        style={{
          ...style,
          padding: "0px 0px 0px 12px",
          alignSelf: "flex-start",
          position: "absolute",
          left: 5
        }}
      >
        <ButtonGroup
          variant="contained"
          aria-label="Theme toggle"
          size="small"
          disableElevation
        >
          <Button
            className="toggle-light"
            aria-label="Toggle light mode"
            style={{
              textTransform: "none",
              width: "71px"
            }}
            color="primary"
            onClick={() => {
              setIsDarkMode(false);
              localStorage.setItem("isDarkMode", false);
            }}
          >
            <FormattedMessage id="Light" />
          </Button>
          <Button
            className="toggle-dark"
            aria-label="Toggle dark mode"
            style={{
              textTransform: "none",
              width: "71px"
            }}
            color="primary"
            onClick={() => {
              setIsDarkMode(true);
              localStorage.setItem("isDarkMode", true);
            }}
          >
            <FormattedMessage id="Dark" />
          </Button>
        </ButtonGroup>
        <CookieBot domainGroupId={"6a6c8641-c0b6-43a2-893d-ee4c97a4ba5d"} />
      </Box>

      <Box
        style={{
          textAlign: "center",
          display: "flex",
          alignItems: "center",
          justifyContent: "center"
        }}
      >
        <Link
          href={messages[userData.lang]["privacyPolicy.url"]}
          target="_blank"
          rel="noreferrer"
          aria-label="Privacy Policy"
        >
          <Typography
            variant="caption"
            component="span"
            style={{ textDecoration: "underline", marginRight: "12px" }}
          >
            <FormattedMessage id="privacyPolicy" />
          </Typography>
        </Link>
        <Link
          href={messages[userData.lang]["impressum.url"]}
          target="_blank"
          rel="noreferrer"
          aria-label="Impressum"
        >
          <Typography
            variant="caption"
            component="span"
            style={{ textDecoration: "underline", marginLeft: "12px" }}
          >
            <FormattedMessage id="impressum" />
          </Typography>
        </Link>{" "}
        <Typography variant="caption" component="span" fontWeight={500}>
          {/* {userData.lang === "de" && "entnehmen."} */}
        </Typography>
      </Box>
    </Box>
  );
}
