import React, { useState, useEffect, useContext } from "react";
import { Box, Grid, Typography, IconButton, Collapse } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import DoneIcon from "@mui/icons-material/Done";

import { FormattedMessage } from "react-intl";
import { formattedMessage } from "../configuration/helpers";
import { EcAuthContext } from "@eclear-ag/ec-auth";

export default function ValidationMessage(props) {
  const [formColor, setFormColor] = useState(false);
  const { userData } = useContext(EcAuthContext);

  useEffect(() => {
    if (
      props.messages[0]?.messages?.filter((e) => e.type === "error").length > 0
    ) {
      setFormColor("error");
    } else setFormColor("success");
  }, [props]);

  const getIcon = (type) => {
    if (type === "success") {
      return (
        <DoneIcon
          aria-label="Success icon"
          style={{
            color: "var(--text-success)",
            fontSize: "12px",
            marginTop: "6px"
          }}
        />
      );
    } else {
      return (
        <CloseIcon
          aria-label="Failure icon"
          style={{
            color: "var(--text-error)",
            fontSize: "12px",
            marginTop: "6px"
          }}
        />
      );
    }
  };

  return (
    <Collapse in={props.visibility} style={{ transformOrigin: "0 0 0" }}>
      <Box className={`box-${formColor}`} style={{ borderRadius: "4px" }}>
        <IconButton
          data-testid="error-close-button"
          title={formattedMessage(userData.lang, "Close")}
          aria-label="Close validation messages"
          color="primary"
          component="span"
          style={{
            width: "1.5rem",
            height: "1.5rem",
            float: "right"
          }}
          onClick={() => props.setVisibility(false)}
        >
          <CloseIcon
            arai-label="Close icon"
            style={{
              fontSize: "18px"
            }}
            className={formColor === "error" ? "text-error" : "text-success"}
          />
        </IconButton>
        {props.messages?.map((item, i) => {
          return (
            <div key={item.title + "-" + i}>
              <div>
                <Typography
                  data-testid={
                    formColor === "error" ? "error-title" : "success-title"
                  }
                  variant="subtitle2"
                  component="span"
                  className={
                    formColor === "error" ? "text-error" : "text-success"
                  }
                >
                  <FormattedMessage id={item.title} />
                </Typography>
              </div>
              <div
                style={{
                  padding: "0px",
                  margin: "0px"
                }}
              >
                <Grid container spacing={1}>
                  {item.messages.map((item, index, arr) => {
                    return (
                      <Grid
                        key={index}
                        item
                        xs={12}
                        sm={arr.length === 1 ? 12 : 6}
                      >
                        <div key={item.title + "-" + i}>
                          <div
                            style={{
                              display: "flex",
                              alignItems: "flex-start"
                            }}
                            key={item.text + "-" + index}
                          >
                            {getIcon(item.type)}
                            <Typography
                              data-testid={
                                item.type === "error"
                                  ? `error-message-${index + 1}`
                                  : `success-message-${index + 1}`
                              }
                              variant="caption"
                              component="span"
                              fontSize={"14px"}
                              className={
                                item.type === "error"
                                  ? "text-error"
                                  : "text-success"
                              }
                              style={{ marginLeft: "8px" }}
                            >
                              {React.isValidElement(item.text) ? (
                                item.text
                              ) : (
                                <FormattedMessage id={item.text} />
                              )}
                            </Typography>
                          </div>
                        </div>
                      </Grid>
                    );
                  })}
                </Grid>
              </div>
            </div>
          );
        })}
      </Box>
    </Collapse>
  );
}
